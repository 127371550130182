import React, { useState, useEffect, useMemo } from "react";
import Meta from "../../Meta";
import TextField from '@mui/material/TextField';

import Link from 'next/link';
import Image from 'next/image';

import Rating from '@mui/material/Rating';


import dynamic from 'next/dynamic';

import Pre from '../../code/pre';
import Avatar from '../../avatar';

import Checkbox from '@mui/material/Checkbox';


import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
// Quill JS

//Formik
import { useFormik, FormikProvider } from 'formik';

//Axios
import axios from "axios";
import config from "../../../utils/config.js";

//Local Storage Session 
import { ReactSession } from 'react-client-session';


const ChatAdd = ({order , setOrder, section}) => {

    //supports resizing and realigning images and iframe videos

    const Editor = dynamic(() => import('../editor'), { ssr: false });

    let formik = {};
    const [warning, setWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [checked, setChecked] = useState(false);
    const [message, setMessage] = useState("");


  
    //  AXIOS REQUEST                            
    const client = axios.create({ baseURL: `${config.API_URL}`});
    const auth = { headers: { Authorization: `Bearer ${config.API_KEY}`}};


    function createMarkup(text) { return {__html: text}; };

    const handleChange = (event) => {
        formik.values.agree = event.target.checked;
        setChecked(event.target.checked);
    };

    // New Activiy ID
    var activity_id ;
    var form = {};

  
  formik = useFormik({
        initialValues: {

            order: order?._id,
            message:message,
            id: order?.id,
            code: order?.code,
            user: order?.user?._id, 
            service: order?.service?._id

        },
        onSubmit: (values) => {

        if(!values.message){
            setSuccess(false);
            setWarning(true);
            setWarningMessage("Please enter your requirements and agree to the terms.");

        }else{

            setWarning(false);
            setSuccess(true);
            setSuccessMessage("Your message has been added.");

            form.id = values.id.toString();
            form.code = values.code;
            form.user = values.user;
            form.service = values.service;

            // Creating array with all prices in number
            let max = 0;
            if(order.activity){
                //Max ID
                max = Object.keys(order.activity).reduce(function(a, b){ return order.activity[a] > order.activity[b] ? a : b });

            }

            activity_id = Number(max) + 1  ;

            

            // Add new object with Activity ID as the key
            const activity = {...order.activity?order.activity:{}, [activity_id] : {
                id : activity_id,
                user: ReactSession.get("_id"),
                message: values.message,
                type:  "message", // notice, message
                section: section ? section : "activity", // activity, requirements, delivery, resolution
                updated: new Date()
            }};

            form.activity = JSON.stringify(activity);


            //alert(JSON.stringify(form, null, 2));
            client.put(`/order/${ order?._id }`, form , auth)
            .then((response) => {
                
                setOrder(response.data);
                handleTextChange(""); //Reset the chat editor to prevent duplicate entries

                const timeId = setTimeout(() => {
                    // After 3 seconds set the success value & alert to false
                    setSuccess(false)
                  }, 3000)


                //alert("200: OK - CHECK DB" + JSON.stringify(order));
            })
            .catch(error => { console.error(error) });
            
        }
    },
  });


  const [delta, setDelta] = useState({
    ops: [{ insert: "Write your article here..\n" }]
  });

  const [dirtyInnerHTML, setDirtyInnerHTML] = useState("");
  const [text, setText] = useState("");
  const [length, setLength] = useState("");

  const handleTextChange = (content) => {
     // console.log(content);
    formik.values.message = content;
    //setRequirements({requirements: content});
      //setDelta(delta); // the delta
      //setDirtyInnerHTML(editor.getHTML()); // innerhtml
      //setText(editor.getText()); // text string
      //setLength(editor.getLength()); // text length
  };

  const requirements_set = order?.requirements;
  const image = ReactSession.get("image") ?  ReactSession.get("image") : config.DEFAULT_USER_IMAGE;

  return (
        <>
        {requirements_set &&

            <div className="w-full">
                <form onSubmit={formik?.handleSubmit}>
                    <div className=" flex items-center py-2">
                        <figure className="mr-5 self-start min-w-[50px]">
                            <Avatar width="40" height="40" image={image} profile={true}/>
                           
                        </figure>

                        <div className="w-full">
                            <h3 className="font-display text-jacarta-700 mb-1 text-md font-semibold dark:text-white flex ">
                            </h3>
                                    <div className="w-full text-left">
                                           
               
                                        <Editor key="editor" theme="snow" light={true} onChange={handleTextChange} value={formik.values.message} />
                                        <Collapse in={success}>
                                        {warning&&

                                            <Alert severity="warning">{warningMessage}</Alert>
                                            }
                                            {success&&

                                            <Alert severity="success">{successMessage}</Alert>
                                            }
                                        </Collapse>
                                    </div>
                                <div className="flex mt-3 w-full justify-end">
                                    
                                <button
                                    className="px-6 py-2  border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex items-center justify-end rounded-full  bg-accent text-white transition-colors border-transparent focus:border-transparent dark:border-transparent">
                                
                                    Send Message
                                </button>
                                </div>
                            
                        </div>
                        </div>

           
                
                    </form>
            </div>      
       
    }
  
    {/*<Pre json={order} />*/}
    </>
  );
};

export default ChatAdd;
