import React, { useState,useEffect } from "react";
import config from "../../utils/config.js";
import { ReactSession } from 'react-client-session';


export default function Index(props) {

  ReactSession.setStoreType("localStorage");

  const image = props.image ? props.image : "";
  const user = props.user ? props.user : "";

  var url = image ? config.IMAGE_URL+"/"+image : "http://www.gravatar.com/avatar/"+user.username+user.wallet+user._id+"?d=retro";

  url = props.profile ? ReactSession.get("image") : url;

  return (
            <img
                {...props}
                src={url}
                className="rounded-full"
            />
  );
}
